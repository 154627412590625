import React from "react"

import "../styles/stars-bg.css"

const StarsBackground = () => (
  <div id="stars-background">
    <div id='stars'/>
    <div id='stars2'/>
    <div id='stars3'/>
  </div>
)

export default StarsBackground
